import { formatApiDate, trimTrailingSpacesInArray } from '../utils'
import {
    GetBuildingFromInvoiceApiResponse,
    GetOrderHeaderAPIResponse,
    GetOrderLinesAPIResponse,
    GetOrdersOrdFileAPIResponse,
    GetPendingOrdersAPIResponse,
    GetShipToCodeApiResponse,
    OrderType,
} from '../utils/interfaces/place-order.interface'
import { axiosInstance } from './api'
import { authQueryFn } from './auth'

export async function getOrders(payload: {
    startDate: string
    endDate: string
    type?: OrderType
}): Promise<GetPendingOrdersAPIResponse> {
    const { userDetails } = authQueryFn()
    const queryParam = new URLSearchParams({
        function: 'getpendingorders',
        alias: userDetails.api_config,
        branch: userDetails.Branch,
        company: userDetails.Company,
        properties: userDetails.Properties,
        startdate: payload.startDate,
        enddate: payload.endDate,
    })
    return axiosInstance('/getpendingorders', {
        params: queryParam,
    }).then((response) => response.data as GetPendingOrdersAPIResponse)
}

export async function getSalesAnalysis({
    startEndDate,
    ...params
}: {
    startEndDate: { from: Date; to: Date }
    buildings?: string
    units?: string
}) {
    const { userDetails } = authQueryFn()
    return axiosInstance.get('/getsalesanalysis', {
        params: {
            function: 'getsalesanalysis',
            alias: userDetails.api_config,
            // branch: '1/*%5C2/*%5C3',
            branch: userDetails.Branches,
            company: userDetails.Company,
            properties: userDetails.Properties,
            startdate: formatApiDate(startEndDate.from),
            enddate: formatApiDate(startEndDate.to),
            ...(params ?? {}),
        },
    })
}

export async function getShipToCodes() {
    const { userDetails } = authQueryFn()
    return axiosInstance
        .get('/testdebug', {
            params: {
                function: 'shiptocodes',
                branch: userDetails.Branches,
                company: userDetails.Company,
                properties: userDetails.Properties,
                alias: userDetails.api_config,
            },
        })
        .then((resp) => resp.data as GetShipToCodeApiResponse)
}

export async function getBuildingFromInvoice() {
    const { userDetails } = authQueryFn()
    return axiosInstance
        .get('/bldgfrominvoice', {
            params: {
                function: 'bldgfrominvoice',
                branch: userDetails.Branches,
                company: userDetails.Company,
                properties: userDetails.Properties,
                alias: userDetails.api_config,
            },
        })
        .then((resp) => resp.data as GetBuildingFromInvoiceApiResponse)
}

export async function getOrdersOrdFile(payload: {
    startDate: Date
    endDate: Date
}): Promise<GetOrdersOrdFileAPIResponse> {
    const { userDetails } = authQueryFn()
    return axiosInstance
        .get('/getordersordfile', {
            params: {
                function: 'getordersordfile',
                branch: userDetails.Branches,
                company: userDetails.Company,
                properties: userDetails.Properties,
                alias: userDetails.api_config,
                startdate: formatApiDate(payload.startDate),
                enddate: formatApiDate(payload.endDate),
            },
        })
        .then(
            (resp) =>
                trimTrailingSpacesInArray(
                    resp.data
                ) as GetOrdersOrdFileAPIResponse
        )
}

export async function getOrderHeader(
    order: string
): Promise<GetOrderHeaderAPIResponse> {
    const { userDetails } = authQueryFn()
    return axiosInstance
        .get('/order', {
            params: {
                function: 'getorderheader',
                branch: userDetails.Branches,
                company: userDetails.Company,
                properties: userDetails.Properties,
                alias: userDetails.api_config,
                order,
            },
        })
        .then(
            (resp) =>
                trimTrailingSpacesInArray(
                    resp.data
                ) as GetOrderHeaderAPIResponse
        )
}

export async function getOrderLines(
    order: string
): Promise<GetOrderLinesAPIResponse> {
    const { userDetails } = authQueryFn()
    return axiosInstance
        .get('/getorderlines', {
            params: {
                function: 'getorderlines',
                branch: userDetails.Branches,
                company: userDetails.Company,
                properties: userDetails.Properties,
                alias: userDetails.api_config,
                order,
            },
        })
        .then(
            (resp) =>
                trimTrailingSpacesInArray(resp.data) as GetOrderLinesAPIResponse
        )
}
