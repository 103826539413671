import {
    Label,
    Input,
    InputProps,
    TextareaProps,
    Textarea,
} from '@broadlume/willow-ui'
export const InputField = ({
    id,
    label,
    labelClassName,
    fieldType,
    ...rest
}: {
    id: string
    label?: string
    labelClassName?: string
    fieldType?: string
} & InputProps &
    TextareaProps) => {
    return (
        <div>
            {label && (
                <Label
                    className={labelClassName}
                    htmlFor={id}
                    dangerouslySetInnerHTML={{ __html: label }}
                ></Label>
            )}
            {fieldType === 'textarea' && <Textarea id={id} {...rest} />}
            {!fieldType && <Input id={id} {...rest} />}
        </div>
    )
}
