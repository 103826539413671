import { CustomDataTable } from '@broadlume/willow-ui'
import { SelectWrapper } from '../pages/order-analysis'

export const sortByFields: Parameters<typeof SelectWrapper>[0]['data'] = [
    {
        value: 'SLS_SALEAMT',
        id: 'saleAmount',
        label: 'Sale Amount',
    },
    {
        value: 'SLS_SALEQTY',
        id: 'saleQuantity',
        label: 'Sale Quantity',
    },
]

export const orderByFields: Parameters<typeof SelectWrapper>[0]['data'] = [
    {
        value: 'desc',
        id: 'desc',
        label: 'Descending',
    },
    {
        value: 'asc',
        id: 'asc',
        label: 'Ascending',
    },
]

export const selectionFields: Parameters<typeof SelectWrapper>[0]['data'] = [
    {
        value: 'SLS_INVDATE',
        id: 'Invoice Date',
        label: 'Invoice Date',
    },
    {
        value: 'SLS_STYLE',
        id: 'Style',
        label: 'Style',
    },
    {
        value: 'SLS_ORDNO',
        id: 'Job Number',
        label: 'Job Number',
    },
    {
        value: 'SLS_SAL1',
        id: 'Sales Person',
        label: 'Sales Person',
    },
    {
        value: 'SLS_INVNO',
        id: 'Invoice Number',
        label: 'Invoice Number',
    },
    {
        value: 'DMH_SHADDR1',
        id: 'Address',
        label: 'Address',
    },
    {
        value: 'PC_TYPE',
        id: 'Product Type',
        label: 'Product Type',
    },
    {
        value: 'DMI_COLOR',
        id: 'Color',
        label: 'Color',
    },
    {
        value: 'BLDG_ONLY',
        id: 'Building',
        label: 'Building',
    },
    {
        value: 'UNIT_ONLY',
        id: 'Unit',
        label: 'Unit',
    },
    {
        value: 'DMI_SHIPTO',
        id: 'Job Address',
        label: 'Job Address',
    },
    {
        value: 'DMI_SOLDTO',
        id: 'Location',
        label: 'Location',
    },
    {
        value: 'SLS_CUSTID',
        id: 'Customer Name',
        label: 'Customer Name',
    },
]

export const unitData = [
    {
        id: 'selectAllUnit',
        isChecked: true,
        label: 'Select All',
        name: 'selectAll',
    },
    {
        id: '1',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '2',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '3',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
]

export const locationData = [
    {
        id: 'selectAllLocation',
        isChecked: true,
        label: 'Select All',
        name: 'selectAll',
    },
    {
        id: '12',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '23',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '34',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
]

export const buildingData = [
    {
        id: 'selectAllBuilding',
        isChecked: true,
        label: 'Select All',
        name: 'selectAll',
    },
    {
        id: '11',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '22',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '33',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
]

export const tableColumns: Parameters<typeof CustomDataTable>[0]['columns'] = [
    {
        name: 'Sale Quantity',
        selector: (row): string =>
            (
                Math.round(
                    parseFloat((row as Record<string, string>).saleQty) * 100
                ) / 100
            ).toString(),
        sortable: true,
        sortFunction: (a, b) => {
            if (a.saleQty > b.saleQty) {
                return 1
            } else if (a.saleQty < b.saleQty) {
                return -1
            }
            return 0
        },
    },
    {
        name: 'UOM',
        selector: (row): string => (row as Record<string, string>).uom,
        grow: 0,
        sortable: true,
    },
    {
        name: 'Sale Total',
        selector: (row): string =>
            '$' +
            (
                Math.round(
                    parseFloat((row as Record<string, string>).saleTotal) * 100
                ) / 100
            ).toLocaleString(),
        sortable: true,
        sortFunction: (a, b) => {
            if (a.saleTotal > b.saleTotal) {
                return 1
            } else if (a.saleTotal < b.saleTotal) {
                return -1
            }
            return 0
        },
        grow: 1,
    },
]

export const checkBoxStyling = {
    root: ['mr-2'],
    mainLabel: ['!mb-2 text-sm font-light'],
    checkboxLabel: ['!text-sm font-light my-0.5 !mx-0'],
    checkbox: ['data-[state=checked]:!bg-willow-ribbon !text-base'],
}
export const orderAnalysisFormDefaultValues = {
    startEndDate: {
        from: new Date(2023, 2, 2),
        to: new Date(2023, 4, 21),
    },
    // endDate: new Date(),
    sortBy: 'SLS_SALEQTY',
    orderBy: 'desc' as const,
    selection1: 'SLS_STYLE',
    selection2: 'PC_TYPE',
    selection3: 'DMI_COLOR',
    selection4: 'BLDG_ONLY',
    selectLocation: [] as string[], //'MANDALAY,0000', 'SURFACES,0000'
    selectBuilding: [] as string[],
    selectUnit: [] as string[], //'5698', '12', '5645'
}

export const tableCustomStyle: Parameters<
    typeof CustomDataTable
>[0]['customStyles'] = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
            cursor: 'pointer',
            backgroundColor: '#FFFFFF',
            '&:nth-child(2n)': {
                backgroundColor: '#FAFAFA',
            },
        },
    },
    headRow: {
        style: {
            height: 'auto',
            minHeight: 'auto',
            backgroundColor: '#FFD990',
        },
    },
    headCells: {
        style: {
            height: '40px',
            backgroundColor: '#FFD990',
            fontSize: '13px',
            fontWeight: 400,
            textTransform: 'capitalize',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            fontFamily: 'moret,serif !importan',
        },
    },
}

export const orderAnalysisTableLegends = [
    {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
            {
                on: 'hover',
                style: {
                    itemOpacity: 1,
                },
            },
        ],
    },
]

export const OrderDetailsMapper = {
    orderDetailsLeftSideMapper: [
        {
            titleKey: 'Order Number',
            valueKey: 'id',
        },
        {
            titleKey: 'Order Date',
            valueKey: 'invoiceDate',
        },
        {
            titleKey: 'Sales Rep',
            valueKey: 'customerName',
        },
        {
            titleKey: 'Install Date',
            valueKey: 'dueDate',
        },
        {
            titleKey: 'Install Time',
            valueKey: 'invoiceDate',
        },
        {
            titleKey: 'Job Status',
            valueKey: 'action',
        },
    ],
    orderDetailsRightSideMapper: [
        {
            titleKey: 'Quote Number',
            valueKey: 'poNumber',
        },
        {
            titleKey: 'Building',
            valueKey: 'addresses',
        },
        {
            titleKey: 'PO Number',
            valueKey: 'poNumber',
        },
        {
            titleKey: 'Special Instructions',
            valueKey: 'poNumber',
        },
        {
            titleKey: 'Order By',
            valueKey: 'orderBy',
        },
    ],
}

export const OrderDetailsHeaderMapper = {
    billTo: [
        {
            titleKey: 'Contact',
            valueKey: 'orderBy',
        },
        {
            titleKey: 'Zip',
            valueKey: 'orderBy',
        },
        {
            titleKey: 'Address',
            valueKey: 'orderBy',
        },
        {
            titleKey: 'Phone 1',
            valueKey: 'orderBy',
        },
        {
            titleKey: 'Building',
            valueKey: 'orderBy',
        },
        {
            titleKey: 'Phone 2',
            valueKey: 'orderBy',
        },
        {
            titleKey: 'City / State',
            valueKey: 'orderBy',
        },
        {
            titleKey: 'Fax',
            valueKey: 'orderBy',
        },
    ],
    shipTo: [
        {
            titleKey: 'Location',
            valueKey: 'orderBy',
        },
        {
            titleKey: 'Zip',
            valueKey: 'orderBy',
        },
        {
            titleKey: 'Contact',
            valueKey: 'orderBy',
        },
        {
            titleKey: 'Phone 1',
            valueKey: 'orderBy',
        },
        {
            titleKey: 'Address',
            valueKey: 'orderBy',
        },
        {
            titleKey: 'Phone 2',
            valueKey: 'orderBy',
        },
        {
            titleKey: 'Building',
            valueKey: 'orderBy',
        },
        {
            titleKey: 'Fax',
            valueKey: 'orderBy',
        },
        {
            titleKey: 'City / State',
            valueKey: 'orderBy',
        },
        {
            titleKey: 'Property Type',
            valueKey: 'orderBy',
        },
    ],
}
