import { Label, Checkbox } from '@broadlume/willow-ui'

type CheckedState = boolean | 'indeterminate'

interface CheckboxProps {
    checked?: boolean
    defaultChecked?: boolean
    disabled?: boolean
    indeterminate?: boolean
    value?: string
    className?: string
    onCheckedChange?(checked: CheckedState): void
    // Add any other specific props here
}

export const CheckBoxField = ({
    id,
    label,
    labelClassName,
    ...rest
}: {
    id: string
    label?: string
    labelClassName?: string
    fieldType?: string
} & CheckboxProps) => {
    return (
        <div className="flex items-center">
            <Checkbox id={id} {...rest} />
            {label && (
                <Label className={`ml-2 ${labelClassName}`} htmlFor={id}>
                    {label}
                </Label>
            )}
        </div>
    )
}
