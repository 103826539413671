import {
    BreadCrumb,
    BreadCrumbItem,
    CustomDataTable,
    DatePicker,
    Label,
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from '@broadlume/willow-ui'
import { ResponsiveBar } from '@nivo/bar'
import { LegendDatum, ResponsivePie } from '@nivo/pie'
import { useForm } from '@tanstack/react-form'
import { zodValidator } from '@tanstack/zod-form-adapter'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FaChevronRight } from 'react-icons/fa6'
import { z } from 'zod'
import { CardContainer, CardRow } from '../components/CardContainer'
import { CheckBoxField } from '../components/checkBoxField'
import LoadingComponent from '../components/LoadingComponent'

import { useQuery } from '@tanstack/react-query'
import { PrimaryButton } from '../components/primary-button'
import { getBuildingFromInvoice, getShipToCodes } from '../services/orders'
import {
    buildingData,
    checkBoxStyling,
    locationData,
    orderAnalysisFormDefaultValues,
    orderAnalysisTableLegends,
    orderByFields,
    selectionFields,
    sortByFields,
    tableColumns,
    tableCustomStyle,
    unitData,
} from '../utils/constants'
import { useOrderAnalysis } from '../utils/hooks/order-analysis.hook'
import { SalesAnalysisType } from '../utils/interfaces/user.interface'
import { orderAnalysisFormValidation } from '../utils/validators/schemas'

export const OrderAnaylysis = () => {
    const analysisForm = useForm({
        defaultValues: orderAnalysisFormDefaultValues,
        validators: {
            onChange: orderAnalysisFormValidation,
        },
        validatorAdapter: zodValidator(),
        onSubmit: () => {
            refetch()
        },
    })

    const startEndDateField = analysisForm.useField({
        name: 'startEndDate',
    })
    const sortByField = analysisForm.useField({
        name: 'sortBy',
    })
    const orderByField = analysisForm.useField({
        name: 'orderBy',
    })
    const selection1Field = analysisForm.useField({
        name: 'selection1',
    })
    const selection2Field = analysisForm.useField({
        name: 'selection2',
    })

    const selection3Field = analysisForm.useField({
        name: 'selection3',
    })
    const selection4Field = analysisForm.useField({
        name: 'selection4',
    })
    const selectLocationField = analysisForm.useField({
        name: 'selectLocation',
    })
    const selectBuildingField = analysisForm.useField({
        name: 'selectBuilding',
    })
    const selectUnitField = analysisForm.useField({
        name: 'selectUnit',
    })

    const graphRef = useRef(null)

    const { data: shipToCodesData, isLoading: isShipToCodesLoading } = useQuery(
        {
            queryKey: ['getShipToCodes'],
            queryFn: getShipToCodes,
            select(data) {
                // "SURFACES - SURFACES PROPERTY MANAGEMENT (1000 MONTREAL STREET)"
                /**
             * {
                    "CADD_NAME": "SURFACES PROPERTY MANAGEMENT",
                    "CADD_ADDRCD": "0000",
                    "CADD_ADDR1": "1000 MONTREAL STREET",
                    "CADD_CUST": "SURFACES",
                    "CADD_COID": "99"
                }
             */
                const allLocations = [
                    { label: 'Select All', value: 'all' },
                    ...data.map((item) => ({
                        label: `${item.CADD_CUST} - ${item.CADD_NAME} (${item.CADD_ADDR1})`,
                        value: `${item.CADD_CUST},${item.CADD_ADDRCD}`,
                    })),
                ]
                handleMultiSelection({
                    value: 'all',
                    data: allLocations,
                    isChecked: true,
                    selectionField: selectLocationField,
                })
                return allLocations
            },
        }
    )

    const { data: buildingFromInvoice, isLoading: isBuildingAndUnitLoading } =
        useQuery({
            queryKey: ['getBuildingFromInvoice'],
            queryFn: getBuildingFromInvoice,
            select: (data) => {
                const buildings: {
                    [key: string]: { label: string; value: string }
                } = {}
                const units: {
                    [key: string]: { label: string; value: string }
                } = {}
                data.forEach((item) => {
                    const addrArray = item.DMH_SHADDR2.split('#')
                    const buildingValue = addrArray[0].replace(/ /g, '')
                    buildingValue.length &&
                        (buildings[buildingValue] = {
                            label: buildingValue,
                            value: buildingValue,
                        })

                    addrArray[1].length &&
                        (units[addrArray[1]] = {
                            label: addrArray[1],
                            value: addrArray[1],
                        })
                })

                const allBuildings = [
                    { label: 'Select All', value: 'all' },
                    ...Object.values(buildings),
                ]

                const allUnits = [
                    { label: 'Select All', value: 'all' },
                    ...Object.values(units),
                ]

                handleMultiSelection({
                    value: 'all',
                    data: allBuildings,
                    isChecked: true,
                    selectionField: selectBuildingField,
                })

                handleMultiSelection({
                    value: 'all',
                    data: allUnits,
                    isChecked: true,
                    selectionField: selectUnitField,
                })

                return {
                    buildings: allBuildings,
                    units: allUnits,
                }
            },
        })

    const {
        data: apiResponse,
        refetch,
        isLoading,
        isFetching,
        isError,
    } = useOrderAnalysis({
        formData: analysisForm.state.values,
    })

    useEffect(() => {
        graphRef?.current?.scrollIntoView()
    }, [apiResponse])

    /**
     * Handles the multi-selection functionality based on the provided parameters.
     *
     * @param id The unique identifier of the selected item.
     * @param isChecked A boolean indicating whether the item is checked or not.
     * @param index The index of the selected item.
     * @param selectionField The field for selecting locations, buildings, or units.
     * @param data The data array containing unit, location, or building information.
     */
    const handleMultiSelection = ({
        value,
        isChecked,
        selectionField,
        data,
    }: {
        value: string
        isChecked: boolean
        selectionField:
            | typeof selectLocationField
            | typeof selectBuildingField
            | typeof selectUnitField
        data: typeof unitData | typeof locationData | typeof buildingData
    }) => {
        if (value.toLowerCase().includes('all')) {
            selectionField.handleChange(
                isChecked ? data.map((eachItem) => eachItem.value) : []
            )
        } else {
            const arr = structuredClone(selectionField.state.value)
            isChecked
                ? arr.push(value)
                : arr.splice(
                      arr.findIndex((item) => item === value),
                      1
                  )
            if (data.slice(1).every((item) => arr.includes(item.value))) {
                arr.push(
                    data.find((item) =>
                        item?.value?.toLowerCase()?.includes('all')
                    )?.value
                )
            } else {
                const selectAllIndex = arr.findIndex((item) =>
                    item.toLowerCase().includes('all')
                )
                selectAllIndex > -1 && arr.splice(selectAllIndex, 1)
            }

            selectionField.handleChange(arr)
        }
        selectionField.validate('change')
    }

    const handleDateRange = (e: Date) => {
        if (
            startEndDateField.state.value.from &&
            !startEndDateField.state.value.to
        ) {
            if (e < startEndDateField.state.value.from) {
                startEndDateField.handleChange({
                    from: e,
                    to: undefined,
                })
            } else {
                startEndDateField.handleChange({
                    from: startEndDateField.state.value.from,
                    to: e,
                })
                // do modal close here.
            }
        } else if (
            startEndDateField.state.value.from &&
            startEndDateField.state.value.to
        ) {
            startEndDateField.handleChange({
                from: e,
                to: undefined,
            })
        }
    }

    const isCheckboxChecked = ({
        selectedArr = [],
        sourceArrLength,
        item,
    }: {
        item: { label: string; value: string }
        selectedArr: string[]
        sourceArrLength: number
    }) => {
        if (item.label.toLowerCase() === 'select all') {
            if (selectedArr.length) {
                if (selectedArr.length === sourceArrLength) {
                    return true
                } else {
                    return 'indeterminate'
                }
            } else {
                return false
            }
        }
        return selectedArr.includes(item.value)
    }

    return (
        <div className="flex flex-col items-stretch justify-start flex-1 mt-5 w-4/5">
            <div className="grid grid-cols-1">
                <h1 className="text-wool font-serif md:text-4xl text-xl font-bold">
                    Order Analysis
                </h1>

                <div className="flex flex-col flex-1">
                    <CardContainer className="flex-[0.4] h-fit mb-3">
                        <CardRow>
                            <div className="flex flex-col mr-2 w-1/2">
                                <Label className="mb-2" htmlFor="startEndDate">
                                    Start Date - End Date
                                </Label>
                                <DatePicker
                                    id="startEndDate"
                                    selected={startEndDateField.state.value}
                                    onDayBlur={startEndDateField.handleBlur}
                                    numberOfMonths={2}
                                    onDayClick={handleDateRange}
                                    className="rounded-sm border-1 w-full"
                                    mode="range"
                                    showOutsideDays={false}
                                />
                            </div>
                        </CardRow>
                        <CardRow>
                            <div className="flex flex-col flex-1 mr-2">
                                <SelectWrapper
                                    data={sortByFields}
                                    label="Sort By"
                                    otherProps={{
                                        selectRootProps: {
                                            onValueChange:
                                                sortByField.handleChange,
                                            value: sortByField.state.value,
                                        },
                                    }}
                                />
                            </div>

                            <div className="flex flex-col flex-1 ml-2">
                                <SelectWrapper
                                    data={orderByFields}
                                    label="Order By"
                                    otherProps={{
                                        selectRootProps: {
                                            onValueChange:
                                                orderByField.handleChange,
                                            value: orderByField.state.value,
                                        },
                                    }}
                                />
                            </div>
                        </CardRow>

                        <CardRow>
                            <div className="flex flex-col flex-1 mr-2">
                                <SelectWrapper
                                    data={selectionFields.filter(
                                        (item) =>
                                            ![
                                                selection2Field.state.value,
                                                selection3Field.state.value,
                                                selection4Field.state.value,
                                            ].includes(item.value)
                                    )}
                                    otherProps={{
                                        selectRootProps: {
                                            value: selection1Field.state.value,
                                            onValueChange:
                                                selection1Field.handleChange,
                                        },
                                    }}
                                    label="Selection 1"
                                    placeholder="Select Any Item"
                                />
                            </div>

                            <div className="flex flex-col flex-1 ml-2">
                                <SelectWrapper
                                    label="Selection 2"
                                    placeholder="Select Any Item"
                                    data={selectionFields.filter(
                                        (item) =>
                                            ![
                                                selection1Field.state.value,
                                                selection3Field.state.value,
                                                selection4Field.state.value,
                                            ].includes(item.value)
                                    )}
                                    otherProps={{
                                        selectRootProps: {
                                            value: selection2Field.state.value,
                                            onValueChange:
                                                selection2Field.handleChange,
                                        },
                                    }}
                                />
                            </div>
                        </CardRow>

                        <CardRow>
                            <div className="flex flex-col flex-1 mr-2">
                                <SelectWrapper
                                    label="Selection 3"
                                    placeholder="Select Any Item"
                                    data={selectionFields.filter(
                                        (item) =>
                                            ![
                                                selection2Field.state.value,
                                                selection1Field.state.value,
                                                selection4Field.state.value,
                                            ].includes(item.value)
                                    )}
                                    otherProps={{
                                        selectRootProps: {
                                            value: selection3Field.state.value,
                                            onValueChange:
                                                selection3Field.handleChange,
                                        },
                                    }}
                                />
                            </div>

                            <div className="flex flex-col flex-1 ml-2">
                                <SelectWrapper
                                    label="Selection 4"
                                    placeholder="Select Any Item"
                                    data={selectionFields.filter(
                                        (item) =>
                                            ![
                                                selection2Field.state.value,
                                                selection3Field.state.value,
                                                selection1Field.state.value,
                                            ].includes(item.value)
                                    )}
                                    otherProps={{
                                        selectRootProps: {
                                            value: selection4Field.state.value,
                                            onValueChange:
                                                selection4Field.handleChange,
                                        },
                                    }}
                                />
                            </div>
                        </CardRow>
                        <CardContainer className="bg-willow-ash-lightest !p-3">
                            <Label className="text-lg font-bold mb-1">
                                Select Building and Units
                            </Label>
                            <CardRow>
                                <BoxSelector
                                    isLoading={isShipToCodesLoading}
                                    data={shipToCodesData?.map((eachItem) => ({
                                        ...eachItem,
                                        isChecked: isCheckboxChecked({
                                            selectedArr:
                                                selectLocationField.state.value,
                                            sourceArrLength:
                                                shipToCodesData.length,
                                            item: eachItem,
                                        }),
                                    }))}
                                    onCheckChange={({ ...rest }) =>
                                        handleMultiSelection({
                                            ...rest,
                                            selectionField: selectLocationField,
                                            data: shipToCodesData,
                                        })
                                    }
                                    classNames={checkBoxStyling}
                                    label="Select Locations"
                                />
                            </CardRow>
                            <CardRow>
                                <BoxSelector
                                    isLoading={isBuildingAndUnitLoading}
                                    data={buildingFromInvoice?.buildings.map(
                                        (eachItem) => ({
                                            ...eachItem,
                                            isChecked: isCheckboxChecked({
                                                selectedArr:
                                                    selectBuildingField.state
                                                        .value,
                                                sourceArrLength:
                                                    buildingFromInvoice
                                                        .buildings.length,
                                                item: eachItem,
                                            }),
                                        })
                                    )}
                                    onCheckChange={({ ...rest }) =>
                                        handleMultiSelection({
                                            ...rest,
                                            selectionField: selectBuildingField,
                                            data: buildingFromInvoice?.buildings,
                                        })
                                    }
                                    classNames={checkBoxStyling}
                                    label="Building"
                                />
                                <BoxSelector
                                    isLoading={isBuildingAndUnitLoading}
                                    data={buildingFromInvoice?.units.map(
                                        (eachItem) => ({
                                            ...eachItem,
                                            isChecked: isCheckboxChecked({
                                                selectedArr:
                                                    selectUnitField.state.value,
                                                sourceArrLength:
                                                    buildingFromInvoice?.units
                                                        ?.length,
                                                item: eachItem,
                                            }),
                                        })
                                    )}
                                    onCheckChange={({ ...rest }) =>
                                        handleMultiSelection({
                                            ...rest,
                                            selectionField: selectUnitField,
                                            data: buildingFromInvoice?.units,
                                        })
                                    }
                                    classNames={checkBoxStyling}
                                    label="Unit"
                                />
                            </CardRow>

                            <PrimaryButton
                                isLoading={
                                    analysisForm.state.isSubmitting ||
                                    isLoading ||
                                    isFetching
                                }
                                type="submit"
                                title="Submit"
                                className="text-center h-6 md:mt-1 mt-2 py-5 w-fit px-12 justify-self-center md:text-base text-sm"
                                disabled={
                                    !analysisForm.state.values.selectBuilding
                                        .length ||
                                    !analysisForm.state.values.selectLocation
                                        .length ||
                                    !analysisForm.state.values.selectUnit
                                        .length ||
                                    !analysisForm.state.canSubmit ||
                                    analysisForm.state.isSubmitting ||
                                    isLoading ||
                                    isFetching
                                }
                                onClick={analysisForm.handleSubmit}
                            />
                        </CardContainer>
                    </CardContainer>
                    <OrderAnalysisVisulaizer
                        formData={apiResponse?.formData}
                        tableData={apiResponse?.apiData}
                        isLoading={isLoading || isFetching}
                        isError={isError}
                        graphRef={graphRef}
                    />
                </div>
            </div>
        </div>
    )
}

export const OrderAnalysisVisulaizer = ({
    formData,
    tableData,
    isLoading,
    isError,
    graphRef,
}: {
    formData?: z.infer<typeof orderAnalysisFormValidation>
    isLoading: boolean
    isError: boolean
    tableData?: SalesAnalysisType[]
}) => {
    const [selectionList, setSelectionList] = useState<SelectionList>([
        {
            id: formData?.selection1,
            label: selectionFields.find(
                (eachItem) => eachItem.value === formData?.selection1
            )?.label,
            dataObj: {},
        },
    ])

    useEffect(() => {
        const data = selectionFields.find(
            (eachItem) => eachItem.value === formData?.selection1
        )
        setSelectionList([
            {
                id: data?.value,
                label: data?.label,
                dataObj: {},
            },
        ])
    }, [formData, tableData])

    useEffect(() => {
        !isLoading &&
            !isError &&
            formData &&
            graphRef?.current?.scrollIntoView()
    }, [isLoading, isError, formData, graphRef])

    /**
     * Groups data by a specified property.
     *
     * @param {SalesAnalysisType[]} data - The data to be grouped.
     * @param {string} groupByCol - The property to group the data by.
     */
    const groupByProperty = ({
        data,
        groupByCol,
    }: {
        data: SalesAnalysisType[]
        groupByCol: string
    }) => {
        if (!data?.length || !groupByCol) {
            return
        }

        const obj = {}

        data.forEach((eachItem) => {
            let primeColValue = eachItem[groupByCol]
            switch (groupByCol) {
                case 'SLS_INVDATE':
                    primeColValue = new Date(
                        primeColValue.slice(0, 4) +
                            '-' +
                            primeColValue.slice(4, 6) +
                            '-' +
                            primeColValue.slice(6)
                    ).toLocaleDateString()
                    break

                default:
                    break
            }
            const existingData = obj[primeColValue] ?? {
                saleQty: 0,
                saleTotal: 0,
                uom: '',
                data: [],
                id: primeColValue,
            }
            obj[primeColValue] = {
                ...existingData,
                data: [...existingData.data, eachItem],
                saleQty:
                    existingData.saleQty +
                    parseFloat(eachItem.SLS_SALEQTY.replaceAll('+', '')) / 100,
                saleTotal:
                    existingData.saleTotal +
                    parseFloat(eachItem.SLS_SALEAMT.replaceAll('+', '')) / 100,
                uom: eachItem.SLS_SUNITS,
            }
        })

        setSelectionList((prev) => {
            const arr = structuredClone(prev)
            arr[arr.findIndex((item) => item.id === groupByCol)] = {
                ...arr[arr.findIndex((item) => item.id === groupByCol)],
                dataObj: obj,
            }
            return arr
        })
    }

    useEffect(() => {
        if (selectionList.length >= 2) {
            const currentItem = selectionList[selectionList.length - 1]
            const prevItem = selectionList[selectionList.length - 2]
            !Object.keys(currentItem.dataObj).length &&
                groupByProperty({
                    data: prevItem.dataObj[prevItem.value]?.data,
                    groupByCol: currentItem.id,
                })
        } else {
            const lastItem = selectionList[selectionList.length - 1]
            !Object.keys(lastItem.dataObj).length &&
                groupByProperty({
                    data: tableData,
                    groupByCol: lastItem?.id,
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectionList])

    if (isLoading) {
        return (
            <CardContainer className="mt-3 flex-[0.6] justify-center items-center">
                <LoadingComponent />
            </CardContainer>
        )
    }
    if (isError) {
        return (
            <CardContainer className="mt-3 flex-[0.6] justify-center items-center">
                <div>Error while fetching data</div>
            </CardContainer>
        )
    }
    if (!formData || !tableData?.length) {
        return (
            <CardContainer className="mt-3 flex-[0.6] justify-center items-center">
                <div>No Data found. Try Adjusting Date Range or filters</div>
            </CardContainer>
        )
    }

    const handleBreadCrumbClick = (e) => {
        setSelectionList((prev) => {
            const newArr = prev.slice(
                0,
                prev.findIndex((each) => each.id === e.target.id) + 1
            )
            delete newArr[newArr.length - 1].value
            return newArr
        })
    }

    return (
        <CardContainer
            ref={graphRef}
            className={'mt-3 flex-[0.6] justify-between'}
        >
            <CardRow>
                <ChartTabs selectionList={selectionList} />
            </CardRow>
            <div className="flex flex-col flex-1 justify-start !w-full">
                <BreadCrumb className="my-4">
                    {selectionList.map((item, index) => (
                        <BreadCrumbItem
                            id={item.id}
                            label={item.label!}
                            classNames={{
                                label: [
                                    selectionList.length - 1 === index
                                        ? 'text-willow-ash-light'
                                        : 'text-willow-gold',
                                    'text-lg',
                                ],
                            }}
                            isLast={selectionList.length - 1 === index}
                            onClick={handleBreadCrumbClick}
                        />
                    ))}
                </BreadCrumb>

                <OrderAnalysisTable
                    formData={formData}
                    selectionList={selectionList}
                    setSelectionList={setSelectionList}
                />
            </div>
        </CardContainer>
    )
}

export const ChartTabs = ({
    selectionList = [],
}: {
    selectionList: SelectionList
}) => {
    const [pieLegendsData, setPieLegendsData] = useState<
        LegendDatum<SelectionListItem>[]
    >([])

    const lastLegendsDataRef = useRef([])

    const updateLegendsData = useCallback((data) => {
        // Compare incoming data with the last stored version
        const isDifferent =
            JSON.stringify(data) !== JSON.stringify(lastLegendsDataRef.current)

        if (isDifferent) {
            // Update state and ref only if data has changed
            setPieLegendsData(data)
            lastLegendsDataRef.current = data
        }
    }, [])

    return (
        <Tabs className="w-full h-full" defaultValue={`pie`}>
            <TabsList
                className="flex flex-row items-center justify-center"
                color="green"
            >
                <TabsTrigger
                    value={`bar`}
                    onClick={() => {}}
                    className={`rounded-s-md data-[state=active]:text-willow-ash-lightest data-[state=inactive]:text-willow-ash-light data-[state=active]:bg-willow-gold data-[state=inactive]:bg-willow-ash-lighter`}
                >
                    Bar Chart
                </TabsTrigger>
                <TabsTrigger
                    value={`pie`}
                    onClick={() => {}}
                    className="rounded-r-md data-[state=active]:text-willow-ash-lightest data-[state=inactive]:text-willow-ash-light data-[state=active]:bg-willow-gold data-[state=inactive]:bg-willow-ash-lighter"
                >
                    Pie Chart
                </TabsTrigger>
            </TabsList>

            <TabsContent
                className="!w-full !h-[400px] !max-h-[400px] !min-h-[300px]"
                value="bar"
            >
                <BarGraph selectionList={selectionList} />
            </TabsContent>

            <TabsContent
                className="!w-full !h-[400px] !max-h-[400px] !min-h-[300px]"
                value="pie"
            >
                <div className="flex flex-row !h-[400px] !max-h-[400px] !min-h-[300px] py-6">
                    <LegendsTable legendsData={pieLegendsData} />
                    <PieGraph
                        chartTitle="Sale Quantity"
                        selectedParam="saleQty"
                        data={Object.values(
                            selectionList[selectionList.length - 1].dataObj
                        )}
                        forwardLegendData={updateLegendsData}
                        margin={{ top: 40, right: 0, bottom: 40, left: 0 }}
                        tooltip={({ datum: { id, value, color } }) => (
                            <div
                                style={{
                                    padding: 12,
                                    color,
                                    background: '#222222',
                                }}
                            >
                                <span>
                                    {
                                        selectionList[selectionList.length - 1]
                                            .label
                                    }
                                    : {id}
                                </span>
                                <br />
                                <span>Total Qty: {value}</span>
                            </div>
                        )}
                    />
                    <PieGraph
                        tooltip={({ datum: { id, value, color } }) => (
                            <div
                                style={{
                                    padding: 12,
                                    color,
                                    background: '#222222',
                                }}
                            >
                                <span>
                                    {
                                        selectionList[selectionList.length - 1]
                                            .label
                                    }
                                    : {id}
                                </span>
                                <br />
                                <span>Total Sales: {value}</span>
                            </div>
                        )}
                        chartTitle="Sale Total"
                        margin={{ top: 40, right: 10, bottom: 40, left: 0 }}
                        selectedParam="saleTotal"
                        data={Object.values(
                            selectionList[selectionList.length - 1].dataObj
                        )}
                    />
                </div>
                {/* <table width={150}>
                    {pieLegendsData.map((item) => (
                        <tr>
                            <div className="w-3 h-3 bg-willow-gold rounded"></div>
                            {item.label}
                        </tr>
                    ))}
                </table> */}
                {/* <LegendsTable legendsData={pieLegendsData} /> */}
            </TabsContent>
        </Tabs>
    )
}

export const BarGraph = ({ selectionList = [] }) => {
    if (!selectionList.length) {
        return null
    }
    return (
        <ResponsiveBar
            data={Object.values(
                selectionList[selectionList.length - 1].dataObj
            )}
            animate={true}
            enableGridY={true}
            keys={['saleQty', 'saleTotal']}
            indexBy="id"
            margin={{ top: 5, right: 5, bottom: 70, left: 50 }}
            padding={0.1}
            groupMode="grouped"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'category10' }}
            tooltipLabel={(props) => {
                return `${props.id.toString().toUpperCase()}`
            }}
            tooltip={(props) => {
                return (
                    <div className="bg-willow-ash-lightest">
                        {props.id.toString().toUpperCase()}:{' '}
                        {Math.round(props.value)}
                    </div>
                )
            }}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true,
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                },
            ]}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -75,
                // legend: selectionList[selectionList.length - 1].label,
                legendPosition: 'middle',
                legendOffset: 65,
                truncateTickAt: 0,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: -40,
                truncateTickAt: 0,
            }}
            enableLabel={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
            }}
            labelPosition="end"
            layout="vertical"
            labelOffset={8}
            legends={orderAnalysisTableLegends}
        />
    )
}

export const PieGraph = ({
    margin = {},
    selectedParam,
    chartTitle = '',
    ...props
}: {
    selectedParam: 'saleQty' | 'saleTotal'
    chartTitle: string

    margin?: Parameters<typeof ResponsivePie>[0]['margin']
} & Parameters<typeof ResponsivePie>[0]) => {
    return (
        <div className="flex flex-col flex-1 items-center justify-start">
            {chartTitle}
            <ResponsivePie
                margin={margin}
                activeOuterRadiusOffset={8}
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsRadiusOffset={0.65}
                arcLabelsSkipAngle={10}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                theme={{
                    tooltip: {
                        container: {
                            background: '#333',
                        },
                    },
                }}
                // forwardLegendData={updateLegendsData}
                value={(item) => parseInt(item[selectedParam])}
                {...props}
            />
        </div>
    )
}

export const OrderAnalysisTable = ({
    formData,
    selectionList,
    setSelectionList,
}) => {
    const handleRowClick = (row) => {
        selectionList.length !== 4 &&
            setSelectionList((prev) => {
                const arr = structuredClone(prev)
                arr[arr.length - 1] = {
                    ...arr[arr.length - 1],
                    value: row.id,
                }
                const newItem = selectionFields.find(
                    (item) =>
                        item.value === formData[`selection${prev.length + 1}`]
                )
                arr.push({
                    id: newItem?.value,
                    label: newItem?.label,
                    dataObj: {},
                })
                return arr
            })
    }
    return (
        <CustomDataTable
            className="rounded-none w-screen"
            classNames={{
                root: [''],
            }}
            persistTableHead={true}
            dense={true}
            defaultSortAsc={formData.orderBy === 'asc'}
            defaultSortFieldId={formData.sortBy === 'SLS_SALEQTY' ? 2 : 4}
            fixedHeader
            fixedHeaderScrollHeight="300px"
            responsive={true}
            columns={[
                {
                    name: selectionList[selectionList.length - 1].label,
                    selector: (row): string =>
                        (row as Record<string, string>).id,
                    sortable: true,
                    cell: (row) => (
                        <div
                            className="flex items-center justify-center"
                            onClick={() => handleRowClick(row)}
                        >
                            <FaChevronRight className="mr-2" /> {row.id}
                        </div>
                    ),
                    grow: 1.2,
                },
                ...tableColumns,
            ]}
            data={Object.values(
                selectionList[selectionList.length - 1].dataObj
            )}
            onRowClicked={handleRowClick}
            customStyles={tableCustomStyle}
        />
    )
}

export const LegendsTable = ({
    legendsData,
}: {
    legendsData: LegendDatum<SelectionListItem>[]
}) => {
    return (
        <CustomDataTable
            className="rounded-none min-w-40 w-40 max-w-40"
            classNames={{
                root: [''],
            }}
            fixedHeader
            fixedHeaderScrollHeight="360px"
            dense={true}
            responsive={true}
            columns={[
                {
                    name: 'Legends',

                    selector: (row): string =>
                        (row as Record<string, string>).label,
                    cell: (row) => (
                        <div className="flex items-center justify-center">
                            <div
                                className={`w-3 h-3 rounded mr-2`}
                                style={{
                                    background: row.color,
                                }}
                            />
                            {row.id}
                        </div>
                    ),
                    // grow: 1.2,
                },
            ]}
            data={legendsData}
            customStyles={tableCustomStyle}
        />
    )
}

export const BoxSelector = ({
    isLoading = false,
    classNames = {
        root: [],
        mainLabel: [],
        checkbox: [],
        checkboxLabel: [],
        checkboxWrapper: [],
    },
    label,
    onCheckChange,
    data = [],
    otherCheckBoxProps = {},
}: {
    isLoading?: boolean
    classNames?: {
        root?: string[]
        mainLabel?: string[]
        checkboxWrapper?: string[]
        checkboxLabel?: string[]
        checkbox?: string[]
    }
    onCheckChange: ({
        value,
        label,
        index,
        isChecked,
    }: {
        value: string
        label: string
        index: number
        isChecked: boolean
    }) => void
    label: string
    data: { value: string; label: string; index: number; isChecked: boolean }[]
    otherCheckBoxProps: Omit<
        Parameters<typeof CheckBoxField>[0],
        'onCheckedChange' | 'id'
    >
}) => {
    const dataComponent = useMemo(() => {
        return isLoading ? (
            <LoadingComponent classNames="self-center w-6 h-6" />
        ) : data.length ? (
            data.map((eachItem) => (
                <CheckBoxField
                    id={eachItem.value}
                    checked={eachItem.isChecked}
                    label={eachItem.label}
                    onCheckedChange={(checked) =>
                        onCheckChange({
                            ...eachItem,
                            isChecked: checked as boolean,
                        })
                    }
                    labelClassName={`text-xs font-normal font-sans ${classNames.checkboxLabel}`}
                    className={`!border-custom-primary_font focus:!border-willow-heart me-2 text-sm ${classNames.checkbox}`}
                    {...otherCheckBoxProps}
                ></CheckBoxField>
            ))
        ) : (
            <CardContainer className="my-1 flex-[0.6] justify-center items-center">
                <div>Error while fetching data</div>
            </CardContainer>
        )
    }, [data, isLoading, classNames, onCheckChange, otherCheckBoxProps])

    return (
        <div className={`flex flex-col flex-1 ${classNames.root}`}>
            <Label
                htmlFor="selectLocations"
                className={`mb-1 ${classNames.mainLabel}`}
            >
                {label}
            </Label>
            <div
                className={`flex flex-col items-start justify-start bg-[#ffffff] max-h-[150px] h-fit overflow-auto p-2 rounded-lg border border-[#D1D1D1] ${classNames.checkboxWrapper}`}
            >
                {dataComponent}
            </div>
        </div>
    )
}

export const SelectWrapper = ({
    data = [],
    classNames = {
        root: [],
        label: [],
    },
    placeholder = 'Select an option',
    label,
    otherProps = {
        selectRootProps: {},
        selectContentProps: {},
        selectTriggerProps: {},
        selectItemProps: {},
    },
}: {
    data: { id: string; label: string; value: string }[]
    placeholder?: string
    label?: string
    classNames?: {
        root?: string[]
        label?: string[]
    }
    otherProps?: {
        selectRootProps?: Parameters<typeof Select>[0]
        selectTriggerProps?: Parameters<typeof SelectTrigger>[0]
        selectContentProps?: Parameters<typeof SelectContent>[0]
        selectItemProps?: Omit<Parameters<typeof SelectItem>[0], 'value'>
    }
}) => {
    return (
        <div className={`${classNames.root}`}>
            {label && (
                <Label className={`mb-2 ${classNames.label}`}>{label}</Label>
            )}
            <Select {...otherProps.selectRootProps}>
                <SelectTrigger
                    {...otherProps.selectTriggerProps}
                    aria-placeholder={placeholder}
                >
                    <SelectValue id={placeholder} placeholder={placeholder} />
                </SelectTrigger>
                <SelectContent {...otherProps.selectContentProps}>
                    {data.map((eachItem) => (
                        <SelectItem
                            id={eachItem.id}
                            value={eachItem.value}
                            {...otherProps.selectItemProps}
                        >
                            {eachItem.label}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    )
}

type SelectionListItem = {
    id?: string
    label?: string
    value?: string
    dataObj: {
        [key: string]: {
            saleQty: number
            saleTotal: number
            data: SalesAnalysisType[]
            id: string
        }
    }
}

type SelectionList = SelectionListItem[]
