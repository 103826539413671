import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
} from '@broadlume/willow-ui'
import { useForm } from '@tanstack/react-form'
import { useMutation } from '@tanstack/react-query'
import { getRouteApi, useNavigate, useRouter } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-form-adapter'
import { z } from 'zod'
import { FormTextInput } from '../components/FormInput'
import { signIn } from '../services/auth'
import { OnboardingContainer } from './onboarding'
import { useCustomToast } from '../components/useCustomToast'
import { emailSchema } from '../utils/validators/schemas'
import { PrimaryButton } from '../components/primary-button'
const router = getRouteApi('/$clientid/_onboarding/login')

const Login = () => {
    const params = router.useParams()
    const router1 = useRouter()
    const navigate = useNavigate({ from: '/' })
    const { toast } = useCustomToast()
    const loginMutation = useMutation({
        mutationKey: ['login'],
        mutationFn: signIn,
    })
    const form = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
        validatorAdapter: zodValidator(),
        onSubmit: async ({ value }) => {
            try {
                await loginMutation.mutateAsync({
                    username: value.email,
                    password: value.password,
                    clientId: params.clientid,
                })
                toast({
                    title: 'Login Successful',
                    description: 'Redirecting to Dashboard',
                })
                setTimeout(() => router1.invalidate(), 1500)
            } catch (error) {
                // setValmsg(true)
                console.log('error login===', error)
                if (error?.status === 301 && error?.response?.data?.redirect) {
                    toast({
                        title: 'Password Expired!',
                        description:
                            'Please reset the password using the code sent on your email.',
                    })
                    navigate({
                        to: '/$clientid/resetpassword',
                        params,
                        search: {
                            email: value.email,
                        },
                    })
                } else {
                    toast({
                        title: 'Invalid Request',
                        description:
                            error?.response?.data?.message ?? error?.message,
                    })
                }
            }
        },
    })

    return (
        <OnboardingContainer>
            <Card className="pt-6 w-2/5 sm:w-4/5 md:w-1/2 lg:w-1/3 rounded-2xl border-none shadow-[0px_15px_50px_0px_#0000001A] p-2 m-auto">
                <CardHeader className="md:p-0 p-1">
                    <CardTitle className="text-center text-2xl font-bold  not-italic font-sans md:mb-0 mb-0">
                        Login
                    </CardTitle>
                </CardHeader>
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        form.handleSubmit()
                    }}
                >
                    <CardContent className="pb-0 md:p-1 px-1">
                        <FormTextInput
                            form={form}
                            validators={{
                                onChange: emailSchema,
                            }}
                            name="email"
                            otherProps={{
                                label: 'Email',
                                type: 'email',
                            }}
                            tabIndex={2}
                        />
                        <FormTextInput
                            form={form}
                            validators={{
                                onChange: z
                                    .string({ message: 'Password is required' })
                                    .min(2, 'Password too short!'),
                            }}
                            otherProps={{
                                label: 'Password',
                                type: 'password',
                            }}
                            name="password"
                            tabIndex={1}
                        />
                    </CardContent>
                    <CardFooter className="grid grid-cols-1 pb-3">
                        <span
                            className="text-right cursor-pointer md:text-base text-sm font-sans font-bold text-[#008FBD] sm:my-1 my-2 cursor justify-self-end w-max"
                            onClick={() =>
                                navigate({ to: '/$clientid/forgotpassword' })
                            }
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (['Enter', ' '].includes(e.key)) {
                                    navigate({
                                        to: '/$clientid/forgotpassword',
                                    })
                                }
                            }}
                        >
                            Forgot Password?
                        </span>
                        <form.Subscribe
                            selector={(state) => [
                                state.canSubmit,
                                state.isSubmitting,
                            ]}
                            children={([canSubmit, isSubmitting]) => (
                                <PrimaryButton
                                    type="submit"
                                    title="Login"
                                    className="text-center h-6 md:mt-1 mt-2 py-5 w-fit px-12 justify-self-center md:text-base text-sm"
                                    disabled={!canSubmit || isSubmitting}
                                />
                            )}
                        />
                    </CardFooter>
                </form>
            </Card>
        </OnboardingContainer>
    )
}

export default Login
