import { createFileRoute, redirect } from '@tanstack/react-router'
import { LandingPage } from '../../pages/landingpage'
import { authQueryFn, getTabs } from '../../services/auth'

export const Route = createFileRoute('/$clientid/_app')({
    beforeLoad: async ({ params, context, location }) => {
        const { queryClient } = context
        const data = await queryClient.fetchQuery({
            queryKey: ['authKeys'],
            queryFn: () => authQueryFn(true),
        })

        // We will add authentication here later
        // Route users to default broadlume page in case of missing client id.
        console.log(
            'beforeload protected params.clientid',
            params,
            location,
            data
        )
        if (!params?.clientid) {
            throw redirect({
                to: '/404',
            })
        }
        const pathArray = location.pathname.split('/')
        if (pathArray[1] !== params.clientid.toLowerCase()) {
            throw redirect({
                to: '/$clientid/' + pathArray.slice(2).join('/'),
                params: {
                    clientid: params.clientid.toLowerCase(),
                },
            })
        }

        const tabList = getTabs({
            userDetails: data.userDetails,
            includePlaceOrder: true,
        })
        if (
            !location.pathname.includes('place-order') &&
            tabList.length &&
            !tabList.find((item) => item.id === location.pathname.split('/')[2])
        ) {
            throw redirect({
                to: '/$clientid/401',
                params,
            })
        }

        if (!data.accessToken || !data.idToken || !data.refreshToken) {
            throw redirect({
                to: '/$clientid/login',
                params,
            })
        }
    },
    component: LandingPage,
})
