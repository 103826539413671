import { DefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { z } from 'zod'
import { authQueryFn } from '../../services/auth'
import { getSalesAnalysis } from '../../services/orders'
import { SalesAnalysisType } from '../interfaces/user.interface'
import { orderAnalysisFormValidation } from '../validators/schemas'

export const useAuthHook = () =>
    useQuery({
        queryKey: ['authKeys'],
        queryFn: () => authQueryFn(),
    })

export const useOrderAnalysis = ({
    formData,
    ...props
}: {
    formData: z.infer<typeof orderAnalysisFormValidation>
} & Omit<
    DefinedInitialDataOptions,
    'queryKey' | 'enabled' | 'queryFn' | 'initialData'
>) =>
    useQuery({
        queryKey: ['analysis'],
        enabled: false,
        queryFn: () => {
            const params: { [key: string]: string } = {}
            // ignore the field. do not pass it. if all are selected
            // if (!formData.selectBuilding.includes('all')) {
            //     // if not, merge the array of string with //**\\ separator.
            //     params['properties'] = formData.selectBuilding.join('//**\\')
            // }

            if (!formData.selectLocation.includes('all')) {
                // if not, merge the array of string with //**\\ separator.
                params['properties'] = formData.selectLocation.join('//**\\\\')
            }

            if (!formData.selectUnit.includes('all')) {
                // if not, merge the array of string with //**\\ separator.
                params['units'] = formData.selectUnit.join('//**\\\\')
            }
            return getSalesAnalysis({
                startEndDate: formData.startEndDate,
                ...params,
            }).then((resp) => ({
                apiData: resp.data as SalesAnalysisType[],
                formData: formData,
            }))
        },
        ...props,
    })
