import { z } from 'zod'

export const emailSchema = z.string({ message: 'Email is required' }).email({
    message: 'Invalid Email address',
})

export const passwordSchema = z
    .string({ message: 'Password is required' })
    .min(8, { message: '8-character minimum length' })
    .max(20, { message: '20-character maximum length' })
    .refine((password) => /[A-Z]/.test(password), {
        message: 'Contains at least 1 uppercase letter',
    })
    .refine((password) => /[a-z]/.test(password), {
        message: 'Contains at least 1 lowercase letter',
    })
    .refine((password) => /[0-9]/.test(password), {
        message: 'Contains at least 1 number',
    })
    .refine((password) => /[!@#$%^&*]/.test(password), {
        message: 'Contains at least 1 special character from the following set',
    })

export const codeSchema = z
    .string()
    .regex(/^[0-9]{6}$/, 'Confirmation Code should be a number of 6 digits')

export const orderAnalysisFormValidation = z.object({
    startEndDate: z.object({ from: z.date(), to: z.date() }),
    // endDate: z.date(),
    sortBy: z.string(),
    orderBy: z.enum(['asc', 'desc']),
    selection1: z.string(),
    selection2: z.string(),
    selection3: z.string(),
    selection4: z.string(),
    selectLocation: z.array(z.string()),
    selectBuilding: z.array(z.string()),
    selectUnit: z.array(z.string()),
})

export const placeOrderFilterValidation = z.object({
    shipTo: z.string({ message: 'ShipTo is required' }).min(1),
    unitType: z.string({ message: 'UnitType is required' }).min(1),
})

export type PlaceOrderFilterValidationType = z.infer<
    typeof placeOrderFilterValidation
>

export const placeOrderFormValidation = z.object({
    orderedBy: z.string({ message: 'OrderedBy is required' }).min(1),
    email: emailSchema,
    poNo: z.string().optional(),
    occupied: z.string().optional(),
    moveInDate: z.date().optional(),
    anyTime: z.string().optional(),
    requestedInstallDate: z.date(),
    onsiteContact: z.string().optional(),
    onsiteContactInfo: z.string().optional(),
    streetAddress: z.string().optional(),
    buildingNumber: z.string().optional(),
    unitNumber: z.string().optional(),
    generalComment: z.string().optional(),
    items: z.record(z.string(), z.boolean()),
})

export type PlaceOrderFormValidation = z.infer<typeof placeOrderFormValidation>
