import _ from 'lodash'

export const trimTrailingSpacesInArray = (
    array: Array<Record<string, string>>
): unknown => {
    return array.map((obj) => {
        Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'string') {
                obj[key] = obj[key].trimEnd()
            }
        })
        return obj
    })
}

export const readableDate = (date: string) => {
    const newDate = []
    if (date === '00000000' || !date) {
        return null
    }
    for (let i = 0; i < 3; i++) {
        let splittedDate = ''
        if (date.length > 4) {
            splittedDate = date.substring(0, 2)
            date = date.substring(2)
        } else {
            splittedDate = date.substring(0, 4)
            date = date.substring(4)
        }
        newDate.push(splittedDate)
    }

    return newDate.join('/')
}

export const groupByDeep = (collection: Array<unknown>, keys: string[]) => {
    return _.chain(collection)
        .map((item) => _.map(keys, (key) => _.get(item, key)))
        .reduce((result, paths, idx) => {
            const items = _.get(result, paths.join('.'), [])
            _.set(result, paths.join('.'), [...items, collection[idx]])
            return result
        }, {})
        .value()
}

export const generateTransactionID = (): string => {
    const year = new Date().getFullYear().toString().padStart(4, '0')
    const month = (new Date().getMonth() + 1).toString().padStart(2, '0')
    const day = new Date().getDate().toString().padStart(2, '0')
    const hour = new Date().getHours().toString().padStart(2, '0')
    const minute = new Date().getMinutes().toString().padStart(2, '0')
    const second = new Date().getSeconds().toString().padStart(2, '0')
    const random = Math.floor(Math.random() * 100)
        .toString()
        .padStart(2, '0')

    return year.slice(-2) + month + day + hour + minute + second + random
}

export const formatApiDate = (date: Date) => {
    return date.toISOString().split('T')[0].replaceAll('-', '')
}

export const convertDateYYYYMMDD = (date: Date) => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}${month}${day}`
}
