import { Input, InputProps, Label } from '@broadlume/willow-ui'
import { ValidationError } from '@tanstack/react-form'

export const CustomInput = ({
    id,
    label,
    errors,
    isTouched,
    customErrorComponent: CustomErrorComponent,
    ...rest
}: {
    id: string
    label?: string
    errors?: ValidationError[]
    isTouched?: boolean
    customErrorComponent: ({
        errorList,
    }: {
        errorList: string[]
    }) => JSX.Element
} & InputProps) => {
    const errorList = (errors && (errors[0] as string))?.split(',') || []
    return (
        <div className="flex ~flex-col ~space-y-1.5">
            {label && <Label htmlFor={id}>{label}</Label>}
            <Input id={id} {...rest} />
            <ErrorComponent
                customErrorComponent={CustomErrorComponent}
                isTouched={isTouched}
                errorList={errorList}
            />
        </div>
    )
}

const ErrorComponent = ({
    errorList = [],
    isTouched,
    customErrorComponent: CustomErrorComponent,
}: {
    errorList: string[]
    isTouched?: boolean
} & Pick<Parameters<typeof CustomInput>[0], 'customErrorComponent'>) => {
    if (!(isTouched && errorList?.length)) {
        return null
    }
    if (CustomErrorComponent) {
        return <CustomErrorComponent errorList={errorList} />
    }
    return errorList.map((error) => {
        return (
            <div className="text-willow-danger text-sm mt-1 block">{error}</div>
        )
    })
}
