export const CardContainer = ({
    className = '',
    children,
}: React.PropsWithChildren<{ className?: string }>) => {
    return (
        <div
            className={`flex flex-col flex-1 p-5 rounded-lg bg-willow-wool-light ${className}`}
        >
            {children}
        </div>
    )
}

export const CardRow = ({ children }: React.PropsWithChildren) => {
    return <div className="flex my-2">{children}</div>
}
