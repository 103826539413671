import { Tabs, TabsList, TabsTrigger } from '@broadlume/willow-ui'
import {
    getRouteApi,
    Outlet,
    useNavigate,
    useRouterState,
} from '@tanstack/react-router'

import { useMemo } from 'react'
import Header from '../components/header'
import { useAuthHook } from '../utils/hooks/order-analysis.hook'
import { getTabs } from '../services/auth'

const router = getRouteApi('/$clientid/_app')

export const LandingPage = () => {
    const navigate = useNavigate()
    const params = router.useParams()
    const routerState = useRouterState()
    const { data: userData } = useAuthHook()
    const search = router.useSearch()
    const yardiSource = useMemo(
        () => search?.source === 'yardi',
        [search?.source]
    )

    const tabList = getTabs({ userDetails: userData?.userDetails })

    return (
        <div>
            <Header isYardiSource={yardiSource} />
            {!yardiSource ? (
                <div className="flex justify-center shadow-[0px_8px_16px_0px_#0000000D] bg-[transparent]">
                    <Tabs
                        className="w-9/12 ~border-none"
                        defaultValue={routerState.location.pathname}
                    >
                        <TabsList className="~bg-transparent py-5 flex flex-row items-center justify-around">
                            {tabList.map((eachTab) => (
                                <TabsTrigger
                                    key={eachTab.id}
                                    id={eachTab.id}
                                    value={`/${params.clientid}/${eachTab.id}`}
                                    onClick={() =>
                                        navigate({
                                            to: '/$clientid/' + eachTab.id,
                                            params,
                                        })
                                    }
                                    className="border-none flex align-middle justify-between gap-2"
                                >
                                    <img
                                        src={eachTab.img}
                                        className={`w-5 h-5 ${routerState.location.pathname === `/${params.clientid}/${eachTab.id}` ? 'text-willow-gold' : ''}`}
                                        alt="cross"
                                    />
                                    <span>{eachTab.label}</span>
                                </TabsTrigger>
                            ))}
                        </TabsList>
                    </Tabs>
                </div>
            ) : null}

            <div
                className={`container h-[100%] items-center ${['/login', '/forgotpassword', '/resetpassword'].includes(location.pathname) ? 'my-8 mb-16 mx-auto sm:px-0 px-5 min-h-screen' : 'min-w-full 2xl:min-h-[92vh] lg:min-h-[85vh] md:min-h-[80vh] min-h-[70vh] min-[414px]:min-h-[77vh] min-[370px]:min-h-[70vh] min-[344px]:min-h-[74vh] min-[390px]:min-h-[77vh] min-[768px]:min-h-[86vh] min-[820px]:min-h-[88vh] min-[1024px]:min-h-[90vh]  h-full flex flex-col mb-2'}`}
            >
                <Outlet />
            </div>
        </div>
    )
}
